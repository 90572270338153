import { useLocale } from "@/hooks/useLocale";
import { Banner } from "@/ui/Banner";
import { DynamicText } from "@/ui/DynamicText";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { DynamicHtml } from "@/ui/DynamicHtml";
import classes from "./styles.module.scss";
import clsx from "classnames";
import ArrowRight from "@/assets/ArrowRight.svg";
import { useLink } from "@/hooks/useLink";

export const WorkForUsPageBannerSection = ({ className, variant }) => {
  const locale = useLocale();
  const link = useLink();

  return (
    <Banner
      className={clsx(className, classes.banner)}
      title={<DynamicText path={`page.work4us.banner.title.${locale}`} tag="h1" />}
      image={{ path: "page.work4us.banner.image", src: "/assets/banners/work4us.png" }}
      variant={variant}
    >
      <Row>
        <Col xs={12} md={6}>
          <DynamicHtml path={`page.work4us.banner.text.${locale}`} className="mb-md-4" />
          <Button href={link("careers.register")} variant="quinary">
            <DynamicText path={`page.work4us.banner.button.${locale}`} tag="span" className="me-2" />
            <ArrowRight width="14" height="12" />
          </Button>
        </Col>
      </Row>
    </Banner>
  );
};

WorkForUsPageBannerSection.defaultProps = {
  className: "",
  variant: "quaternary",
};
