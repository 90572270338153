import { DynamicText } from "@/ui/DynamicText";
import { Col, Container, Row } from "react-bootstrap";
import { useLocale } from "@/hooks/useLocale";
import { DynamicHtml } from "@/ui/DynamicHtml";
import classes from "./styles.module.scss";
import clsx from "classnames";
import Image from "@/ui/Image";

export const WorkForUsPageBenefitsSection = ({ className }) => {
  const locale = useLocale();

  return (
    <div className={clsx(className, classes.benefits)}>
      <Container>
        <div className="mx-2">
          <Row>
            <Col xs={12} lg={6} className="bg-light px-4 py-5 px-md-5 text-overlay">
              <div className="text-overlay__content ms-0 ms-md-4">
                <DynamicText tag="h4" className="mb-3 mb-md-5" path={`page.work4us.benefits.title.${locale}`} />
                <DynamicHtml path={`page.work4us.benefits.text.${locale}`} />
              </div>
              <Image
                className="text-overlay__background"
                img={{ path: `page.work4us.benefits.bg`, src: "/assets/grid/bg.png" }}
                size="642x948"
                alt=""
              />
            </Col>
            <Col xs={12} lg={6} className="p-5">
              <Image
                img={{
                  path: "page.work4us.benefits.image",
                  src: "/assets/work4us/benefits/1.jpg",
                }}
                size="x650"
                alt=""
              />
            </Col>
            <Col xs={12} lg={6} className="bg-primary text-white px-4 py-5 px-md-5 text-overlay">
              <div className="text-overlay__content">
                <DynamicText path={`page.work4us.benefits.2.title.${locale}`} tag="h4" className="mb-3 mb-md-5" />
                <DynamicHtml path={`page.work4us.benefits.2.text.${locale}`} />
              </div>
              <Image
                className="text-overlay__background"
                img={{ path: `page.work4us.benefits.bg`, src: "/assets/grid/transparent.png" }}
                size="642x948"
                alt=""
              />
            </Col>
            <Col xs={12} lg={6} className="p-5 position-relative">
              <Image
                img={{
                  path: "page.work4us.benefits.2.image",
                  src: "/assets/work4us/benefits/2.jpg",
                }}
                size="x650"
                alt=""
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
