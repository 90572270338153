import { Content } from "@/ui/Content";
import * as additionalComponents from "@/components/WorkForUsPage";
import { router } from "@/router";
import { stripTags } from "@/functions/stripTags";
import { getGlobal } from "@/functions/getGlobal";
import { jobs_helper } from "@/helpers/jobs_helper";
import { insights_helper } from "@/helpers/insights_helper";
import sourceflowEnums from "@/sourceflow.enums";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} additionalComponents={additionalComponents} />
    </>
  );
}

export async function getStaticProps({}) {
  const global = getGlobal();
  const insights = insights_helper.fetch({ limit: 3, filter: (i) => i?.tags?.includes("Gravitas News") });
  const jobs = jobs_helper.fetch({
    limit: 12,
    filter: (i) => JSON.stringify(i.categories).includes(sourceflowEnums.categories.sectors.internal),
  });

  const routerProp = router["careers"];
  const titleKey = "page.work4us.banner.title";

  return {
    props: {
      meta: {
        en: {
          title: stripTags(global[`${titleKey}.en`]),
          canonical: `${sourceflowEnums.config.baseUrl}${routerProp["en"]}`,
        },
      },
      router: routerProp,
      content: [
        { component: "WorkForUsPageBannerSection" },
        {
          component: "JobSearchWidget",
          props: {
            jobsUrl: "/join-us/jobs",
            pathPrefix: "jobs.join-us.search",
            variant: "tertiary",
            linksVariant: "tertiary-inverse",
            fullWidth: false,
            roles: "join us",
          },
        },
        { component: "WorkForUsPageContentSection" },
        { component: "WorkForUsPageRewardsSection" },
        { component: "ValuesSection", props: { variant: "quaternary", tags: "Careers" } },
        {
          component: "LatestJobsSection",
          props: {
            items: jobs,
            variant: "quaternary",
            cardVariant: "quaternary",
            cardBtnVariant: "quinary",
            jobsUrl: "/join-us/jobs",
          },
        },
        { component: "WorkForUsPageLearningSection", props: { variant: "tertiary" } },
        { component: "GallerySection", props: { title: false, pathPrefix: "page.join-us.gallery" } },
        {
          component: "InsightsSection",
          props: {
            title: { path: "page.work4us.insights.title" },
            items: insights,
            sidebar: true,
            variant: "quaternary",
            cardVariant: "quinary",
            cardBtnVariant: "quinary",
            tags: [
              { label: "All news", url: "/blog" },
              { label: "DEI", url: "/blog/tag/dei" },
              { label: "Gravitas News", url: "/blog/tag/gravitas-news" },
              { label: "Videos", url: "/blog/tag/videos" },
            ],
          },
        },
        { component: "AwardsSection", props: { className: "py-4 py-md-5", variant: "quaternary" } },
      ],
    },
  };
}
