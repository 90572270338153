import clsx from "classnames";
import Image from "@/ui/Image";
import { TabList } from "@/ui/TabList";
import { useLocale } from "@/hooks/useLocale";
import classes from "./styles.module.scss";
import { rewards_helper } from "@/helpers/rewards_helper";
import { cleanMarkup } from "@/functions/cleanMarkup";

export const WorkForUsPageRewardsSection = ({ className }) => {
  const items = rewards_helper.fetch();
  const locale = useLocale();

  return (
    <div className={clsx(className, classes.tabs)}>
      <TabList
        title={{ path: "page.work4us.rewards.title" }}
        text={{ path: "page.work4us.rewards.text" }}
        variant="quinary"
        items={items.map((i, k) => ({
          title: i[`title_${locale}`],
          content: (
            <div key={k} className={clsx(classes.tabs__content, "p-4 p-md-5 me-md-5")}>
              <Image img={i.icon} size="100x100" alt="" className="mb-4" />
              <div className="mx-md-5" dangerouslySetInnerHTML={{ __html: cleanMarkup(i[`text_${locale}`]) }} />
            </div>
          ),
        }))}
      />
    </div>
  );
};

WorkForUsPageRewardsSection.defaultProps = {
  className: "py-4 py-md-5",
};
