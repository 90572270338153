import clsx from "classnames";
import { useLocale } from "@/hooks/useLocale";
import classes from "./styles.module.scss";
import { learning_helper } from "@/helpers/learning_helper";
import { Container, Stack } from "react-bootstrap";
import { Heading } from "@/ui/Heading";
import { DynamicText } from "@/ui/DynamicText";
import { Carousel } from "@/ui/Carousel";

export const WorkForUsPageLearningSection = ({ className, title, variant }) => {
  const items = learning_helper.fetch();
  const locale = useLocale();

  return (
    <div className={clsx(className, classes.values)} data-variant={variant}>
      <Container>
        <Heading className="mb-1" variant={variant}>
          <DynamicText path={title ? `${title.path}.${locale}` : `page.work4us.learning.title.${locale}`} tag="h2" />
        </Heading>
        <Carousel
          items={items.map((i, k) => (
            <Stack key={k} className={clsx(classes.card, "flex-column flex-md-row")}>
              <div style={{ backgroundImage: `url(${i.image})` }} className="pe-5">
                <img src={i.icon} alt="" className="mb-3 mb-md-4" />
                <div className="me-md-5 px-5" dangerouslySetInnerHTML={{ __html: i[`title_${locale}`] }} />
              </div>
              <div className="p-3 ps-5 p-md-5">
                <div dangerouslySetInnerHTML={{ __html: i[`text_${locale}`] }} />
              </div>
            </Stack>
          ))}
          indicators={false}
          controls={true}
          visibleCount={1}
        />
      </Container>
    </div>
  );
};

WorkForUsPageLearningSection.defaultProps = {
  className: "py-4 py-md-5",
  variant: "primary",
};
