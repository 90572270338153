import clsx from "classnames";
import { Container } from "react-bootstrap";
import classes from "./styles.module.scss";
import { Heading } from "@/ui/Heading";
import Back from "@/assets/Back.svg";
import VisibilitySensor from "react-visibility-sensor";
import { useState } from "react";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import imagesMetaData from "@/../.sourceflow/image_metadata.json";

export const Banner = ({ className, children, backlink, title, image, variant }) => {
  const [visible, setVisible] = useState(false);

  return (
    <VisibilitySensor onChange={() => setVisible(true)}>
      <div className="px-2 px-mb-4 mb-4">
        <div className={clsx(className, classes.banner, `variant-${variant}`, { visible }, "bg-light")}>
          {image && (
            <SourceFlowImage
              className={classes.banner__image}
              path={image.path}
              src={image.src}
              size="1480x600"
              alt=""
              imagesMetaData={imagesMetaData}
            />
          )}
          <Container className={classes.banner__header}>
            {backlink ? (
              <>
                <div className="pt-4 mt-md-5">
                  <a href={backlink.url} className={classes.banner__backlink}>
                    <Back className="me-2" /> {backlink.label}
                  </a>
                </div>
                {title && (
                  <Heading className="pt-3 mb-4" variant={variant}>
                    {title}
                  </Heading>
                )}
              </>
            ) : (
              <>
                {title && (
                  <Heading
                    className="pt-4 pt-md-5 mt-md-5 mb-4 mb-md-5"
                    containerClassName="ms-md-4 ps-4"
                    variant={variant}
                  >
                    {title}
                  </Heading>
                )}
              </>
            )}
          </Container>
          <Container className={clsx(classes.banner__content, "pb-5")}>{children}</Container>
        </div>
      </div>
    </VisibilitySensor>
  );
};

Banner.defaultProps = {
  variant: "primary",
  image: {
    path: null,
    src: "/assets/banners/default.png",
  },
};
