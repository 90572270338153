import { useLocale } from "@/hooks/useLocale";
import { SeriousAboutTalent } from "@/ui/SeriousAboutTalent";
import Image from "@/ui/Image";
import { Col } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import YoutubeVideoModal from "@/ui/YoutubeVideoModal";
import { getGlobal } from "@/functions/getGlobal";
import { stripTags } from "@/functions/stripTags";
import SourceFlowText from "@sourceflow-uk/sourceflowtext";

export const WorkForUsPageContentSection = ({ className, variant }) => {
  const locale = useLocale();
  const global = getGlobal();

  return (
    <SeriousAboutTalent
      className={className}
      tag={`page.work4us.content.tag.${locale}`}
      title={`page.work4us.content.title.${locale}`}
      text={`page.work4us.content.text.${locale}`}
      details={`page.work4us.details.text.${locale}`}
      variant={variant}
    >
      <Col xs={12} lg={6}>
        {/* START: CMS Tools editable fix */}
        <SourceFlowText
          global={global}
          path={`page.work4us.content.video.${locale}`}
          type="text"
          className="visually-hidden"
        />
        {/* END: CMS Tools editable fix */}
        <YoutubeVideoModal
          video_embed_url={stripTags(global[`page.work4us.content.video.${locale}`]).trim()}
          variant={variant}
        >
          <div className="ratio ratio-4x3 mt-4">
            <Image
              img={{ path: "page.work4us.content.image", src: "/assets/work4us/content/1.jpg" }}
              size="x650"
              alt=""
              style={{ objectFit: "cover", objectPosition: "center right" }}
            />
          </div>
        </YoutubeVideoModal>
      </Col>
    </SeriousAboutTalent>
  );
};

WorkForUsPageContentSection.defaultProps = {
  className: "py-4 py-md-5",
  variant: "quaternary",
};
